<template>
  <div class="af-header-desktop">
    <div class="container is-flex">
      <portal-target name="breadcrumb-destination"></portal-target>

      <div v-if="timeLeft" class="ml-auto mr-4 has-separator">
        <p class="has-text-grey-3 is-size-7 mb-0">
          {{ $t("af:header.title.time_left") }}
        </p>

        <span class="has-text-grey-darker" :style='{"fontSize":"14px"}'>
          {{ secondsToMinutes(timeLeft) }}
        </span>
      </div>

      <div :class="{ 'ml-4': timeLeft, 'ml-auto': !timeLeft }">
        <p class="has-text-grey-3 is-size-7 mb-0">
          {{ $t("af:user_header.title.call_us") }}
        </p>

        <a
          class="has-text-weight-medium phone-number has-text-grey-dark"
          :href="`tel:${phoneNumber}`"
          >{{ phoneNumber }}</a
        >
      </div>
    </div>
  </div>
</template>

<script>
import i18n from "@/plugins/i18n";
import { secondsToMinutes } from "@/utils/util";
import { mapGetters } from "vuex";

export default {
  name: "UserHeaderDesktop",
  methods: { secondsToMinutes },
  computed: {
    phoneNumber() {
      return i18n.t("af:user_header.title.phone_number");
    },
    ...mapGetters({
      timeLeft: "session/timer",
    }),
  },
};
</script>

<style scoped lang="scss">
.af-header-desktop {
  display: none;
  flex-wrap: nowrap;
  align-items: center;
  background: transparent;
  padding: 1rem 0;

  .phone-number {
    font-size: 14px;
  }
}

.mbh {
  .af-header-desktop {
    background: white;
  }
}

@media only screen and (min-width: 769px) {
  .af-header-desktop {
    display: flex;
  }
}

.has-separator {
  position: relative;

  &:after {
    content: "";
    position: absolute;
    right: -1rem;
    height: 80%;
    top: 10%;
    width: 1px;
    background: #dfe6ef;
  }
}
</style>
